.banner {
  width: 100%;
}

.jumbotron {
  padding-top: 3rem;
  padding-right: 3rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
  width: 100%;
  text-align: right !important;
}

.jumbo {
  /* padding-top: 1rem; */
  padding: 1rem;
  /* padding-right:3rem; */
  background-color: #e9ecef;
  border-radius: 0.3rem;
  width: 100%;
  text-align: right !important;
}

.profile-pic {
  width: 50%;
  max-height: 200px;
  border-radius: 100px;
}

.person-wrapper {
  margin-bottom: 2em;
}

.home-logo {
  float: right;
}

.banner {
  max-height: 5rem !important;
}

.mystyle {
  height: 30rem;
  width: 100%;
}

.hover {
  cursor: pointer;
}

.carousel img {
  height: fit-content;
}

.mid-row {
  padding: 5% 0% 5% 0%;
}

.mid-row .container p.text-header {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.26px;
  width: 55%;
  margin-bottom: 10px;
}

/* .mid-row .container{
width: 55%;
} */

.mid-row .container h2 span {
  background: #0065ff;
  width: 70px;
  height: 4px;
  margin: 20px 0 0;
  display: block;
}

.mid-row .container .list ul {
  margin: 35px 0 0;
  padding: 0;
}

.mid-row .container .list ul li {
  margin: 0;
  padding: 0;
  position: relative;
  width: calc(20% - 20px);
  background: #fff;
  border: 1px solid #dfe1e6;
  min-height: 220px;
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.mid-row .container .list ul li:hover {
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.mid-row .container .list ul li div {
  position: absolute;
  bottom: 35px;
  left: 30px;
  z-index: initial;
}

.mid-row .container .list ul li div p.heading {
  font-size: 20px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.mid-row .container .list ul li div p.text {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0 0;
  margin: 0;
}

.mid-row .container .list ul li span.sprite-image {
  width: 90px;
  /* background:url(../../images/webp/advantages-sprite.webp) no-repeat; */
  background: url(./webP/advantages-sprite.webp) no-repeat;
  height: 90px;
  display: inline-block;
  background-size: 105%;
  margin: 20px 0 0 10px;
}

.mid-row .container .list ul li span.sprite-image.lowest-price {
  background-position: 0 -315px;
}
.mid-row .container .list ul li span.sprite-image.unbiased-advice {
  background-position: 0 -420px;
}
.mid-row .container .list ul li span.sprite-image.reliable {
  background-position: 0 -102px;
}
.mid-row .container .list ul li span.sprite-image.claim-support {
  background-position: 0 0;
}
.mid-row .container .list ul li span.sprite-image.happy-help {
  background-position: 0 -206px;
}
.mid-row .container .list ul li:last-child {
  margin: 0;
}

.show {
  opacity: 1;
}

.remove {
  display: none;
}

h3 {
  text-align: left;
  width: 300px;
  margin: 20px 0 0;
  font-weight: bold;
  background: -webkit-linear-gradient(#3a8851, #207b42, #4c9a2c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h5 {
  text-align: left;
  width: 500px;
  margin: 20px 0 0;
  font-weight: bold;
  background: -webkit-linear-gradient(#1f9974, #207b42, #87e62f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.surveyContent {
  text-align: center;
  height: 480px;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner::before,
.toggle-switch-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: "Yes";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #f90;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner::after {
  content: "No";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.mobileInput {
  width: inherit !important;
}

.borderInput {
  border: 1px solid #b5a4a0;
  border-top-color: rgb(181, 164, 160);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(181, 164, 160);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(181, 164, 160);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(181, 164, 160);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  padding: 0 20px;
  border-radius: 30px;
}

.nameInput {
  height: 2rem;
  width: inherit;
}

.callbackButton {
  font-weight: 700;
  padding: 14px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
  border-radius: 27.5px;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.1);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  text-transform: uppercase;
  margin-top: 2px;
  display: inline-block;
  cursor: pointer;
  border: 0;
  position: relative;
}

.primary {
  color: #fefefe;
  background: linear-gradient(120deg, #ff8f00, #ff5a00 44%);
  background-image: linear-gradient(
    120deg,
    rgb(255, 143, 0),
    rgb(255, 90, 0) 44%
  );
}

.secondary {
  color: #fefefe;
  background: linear-gradient(120deg, white, grey 44%);
  background-image: linear-gradient(120deg, lightGrey, grey 44%);
}

.info {
  color: blue;
}

.hold-on {
  position: "absolute";
  height: 75px;
}

.homeModalHeader {
  border-bottom: none;
  padding: 0rem 1rem 0rem 0rem;
}

.homeModalBody {
  padding: 0rem;
  overflow-y: auto;
}

.row {
  margin: 0rem;
  justify-content: center;
}

@media all and (max-width: 480px) {
  .mid-row .container .list ul li {
    width: 50%;
  }
}
