* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

nav {
  display: flex;
  justify-content: space-around;
  min-height: 10vh;
  align-items: center;
  font-family: inter;
  background-color: #ff5f6d;
  color: #fff;
}

nav .logo {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

nav .nav-links {
  display: flex;
  justify-content: space-around;
  width: 45%;
}

nav .nav-links a {
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  color: #fff;
}

nav .nav-links a:hover {
  border-bottom: 1px solid #fff;
}

nav .burger {
  font-size: 20px;
  display: none;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  nav .burger {
    display: block;
  }

  nav .nav-links {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100vh;
    background: #ff4e5d;
    padding: 40px;
    width: 40%;
    transform: translateX(-500px);
    transition: 0.5s ease-in-out;
    z-index: 1;
  }

  nav .nav-links li {
    padding: 25px;
    border-bottom: 1px solid #ccc;
  }

  nav .nav-links li:last-of-type {
    border-bottom: 0px solid #ccc;
  }

  nav .nav-links a:hover {
    border-bottom: 0px solid #fff;
  }
}

@media all and (max-width: 480px) {
  .modal-container {
    width: 300px;
  }
}
